.contact-us-section.container-fluid {
  padding-top: 30px;
  width: 100vw;
  height: 100%;
  color: white;
  justify-content: center;
  align-items: center;
  background: url("/img/left_shape.svg") left bottom no-repeat,
  url("/img/right_shape.svg") right top no-repeat,
  #123b8b;
}

form {
  width: 100%;
}

.text-area.form-control {
  height: 7.2rem !important;
}

@media (max-width: 768px) {
  .mt-5.form {
    margin-top: 3rem !important;
  }

  .mt-5 {
    margin-top: 0rem !important;
  }
}
