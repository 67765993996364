.team-section {
  width: 100vw;
  height: 100%;
}

.slider-team.slick-slider.slick-initialized{
  width:80vw;
}

.slick-prev,
.slick-next {
  height: 55px;
  width: 55px;
  background: white;
  color: #fff;
  box-shadow: 1px 2px 14px rgba(0, 0, 0, 0.75);
  /* to position the arrows left and right at the bottom of slider */
  z-index: 2;
  bottom: 0;
  border-radius: 50%;
}

.slick-next:before {
  content: url("/img/Arrow_right_grey.svg");
  background: white;
  color: #fff;
}
.slick-prev:before {
  content: url("/img/Arrow_left_grey.svg");
  background: white;
  color: #fff;
}

@media (min-width: 768px) {
  .team-section-header {
    font-size: 4vw;
  }

  .team-section-text__responsive{
    font-size: 1.2vw;
  }
}

@media (max-width: 768px) {
  .team-section-header {
    font-size: 50px;
  }

  .team-section-text__responsive{
    font-size: 2.7vw;
  }
}

.team-member-photo{
  width:10rem;
}
