@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif!important;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.fill {
  width: 100%;
}

.features-image{
  margin-top:-83rem;
  z-index: -1;
}

@media (max-width: 768px){
  .navbar-brand{
    margin-left: 1rem;
  }

  .nav-link-item{
    padding-left: 0px;
    margin-right: 0px;
  }
}


.landing-section {
  background-color: #123b8b;
  background-image:url("/img/rectangle_landing_page.svg");
  background-repeat: no-repeat;
  width: 100vw;
  height:100%;
}

.fill {
  width: 100%;
}

.landing-section-header {
  text-align: left;
  color: white;
}

.landing-section-text {
  text-align: left;
  color: white;
}

@media (min-width: 768px) {
  .landing-section-header {
    font-size: 4vw;
  }

  .landing-section-text__responsive{
    font-size: 1.2vw;
  }
}

@media (max-width: 768px) {
  .landing-section-header {
    font-size: 50px;
  }

  .landing-section-text__responsive{
    font-size: 2.7vw;
  }

  .landing-section-content{
    padding-bottom: 5vw
  }
}




.why-gale-section.container-fluid {
  padding-top: 30px;
  width: 100vw;
  height: 100%;
}

.fill {
  width: 100%;
}

.icon{
  width: 20px;
}

.why-gale-section-header {
  text-align: left;
}

.why-gale-section-text {
  text-align: left;
}

@media (min-width: 768px) {

  .why-gale-section-header {
    font-size: 2.2vw;
  }

  .why-gale-section-text__responsive{
    font-size: 1.2vw;
    padding-left: calc(16px - (1640px - 100vw)/152);
  }

  .icon{
    width: calc(27px - (1640px - 100vw)/152);
  }
}

@media (max-width: 768px) {

  .why-gale-section-header {
    font-size: 36px;
  }

  .why-gale-section-text__responsive{
    font-size: 16px;
    padding-left: 1rem;
  }

  .icon{
    width: 27px;
  }

  .why-gale-section-content{
    padding-bottom: 5vw
  }
}




.team-section {
  width: 100vw;
  height: 100%;
}

.slider-team.slick-slider.slick-initialized{
  width:80vw;
}

.slick-prev,
.slick-next {
  height: 55px;
  width: 55px;
  background: white;
  color: #fff;
  box-shadow: 1px 2px 14px rgba(0, 0, 0, 0.75);
  /* to position the arrows left and right at the bottom of slider */
  z-index: 2;
  bottom: 0;
  border-radius: 50%;
}

.slick-next:before {
  content: url("/img/Arrow_right_grey.svg");
  background: white;
  color: #fff;
}
.slick-prev:before {
  content: url("/img/Arrow_left_grey.svg");
  background: white;
  color: #fff;
}

@media (min-width: 768px) {
  .team-section-header {
    font-size: 4vw;
  }

  .team-section-text__responsive{
    font-size: 1.2vw;
  }
}

@media (max-width: 768px) {
  .team-section-header {
    font-size: 50px;
  }

  .team-section-text__responsive{
    font-size: 2.7vw;
  }
}

.team-member-photo{
  width:10rem;
}

.contact-us-section.container-fluid {
  padding-top: 30px;
  width: 100vw;
  height: 100%;
  color: white;
  justify-content: center;
  align-items: center;
  background: url("/img/left_shape.svg") left bottom no-repeat,
  url("/img/right_shape.svg") right top no-repeat,
  #123b8b;
}

form {
  width: 100%;
}

.text-area.form-control {
  height: 7.2rem !important;
}

@media (max-width: 768px) {
  .mt-5.form {
    margin-top: 3rem !important;
  }

  .mt-5 {
    margin-top: 0rem !important;
  }
}

.footer-section.container-fluid{
  background-color: #0D122A;
  color: white;
}

@media (min-width:576px){
  .copyright{
    margin-top:-1rem;
    padding-bottom: 1.2rem;
  }

  .gale-logo{
    padding-top: 0rem!important;
  }
}

@media (max-width:768px){
  .social-media-icons{
    margin-left: auto !important
  }

  .address{
    margin-bottom: 1rem;
  }
}

@media (min-width:768px){
  .address{
    margin-left: auto !important
  }
}

.gale-logo-big{
  width:150px;
}

.features-section {
  width: 100vw;
  height: 100%;
}

.slider-features.slick-slider.slick-initialized{
  width:80vw;
}


/* slick arrows */
.slick-prev,
.slick-next {
  height: 55px;
  width: 55px;
  background: white;
  color: #fff;
  box-shadow: 1px 2px 14px rgba(0, 0, 0, 0.75);
  /* to position the arrows left and right at the bottom of slider */
  position: absolute;
  z-index: 1;
  bottom: 0;
  border-radius: 50%;
  opacity: 1.5;
}

.slick-next:before {
  content: url("/img/Arrow_right_grey.svg");
  background: white;
  color: #fff;
}
.slick-prev:before {
  content: url("/img/Arrow_left_grey.svg");
  background: white;
  color: #fff;
}



