.features-section {
  width: 100vw;
  height: 100%;
}

.slider-features.slick-slider.slick-initialized{
  width:80vw;
}


/* slick arrows */
.slick-prev,
.slick-next {
  height: 55px;
  width: 55px;
  background: white;
  color: #fff;
  box-shadow: 1px 2px 14px rgba(0, 0, 0, 0.75);
  /* to position the arrows left and right at the bottom of slider */
  position: absolute;
  z-index: 1;
  bottom: 0;
  border-radius: 50%;
  opacity: 1.5;
}

.slick-next:before {
  content: url("/img/Arrow_right_grey.svg");
  background: white;
  color: #fff;
}
.slick-prev:before {
  content: url("/img/Arrow_left_grey.svg");
  background: white;
  color: #fff;
}


