@media (max-width: 768px){
  .navbar-brand{
    margin-left: 1rem;
  }

  .nav-link-item{
    padding-left: 0px;
    margin-right: 0px;
  }
}

