.landing-section {
  background-color: #123b8b;
  background-image:url("/img/rectangle_landing_page.svg");
  background-repeat: no-repeat;
  width: 100vw;
  height:100%;
}

.fill {
  width: 100%;
}

.landing-section-header {
  text-align: left;
  color: white;
}

.landing-section-text {
  text-align: left;
  color: white;
}

@media (min-width: 768px) {
  .landing-section-header {
    font-size: 4vw;
  }

  .landing-section-text__responsive{
    font-size: 1.2vw;
  }
}

@media (max-width: 768px) {
  .landing-section-header {
    font-size: 50px;
  }

  .landing-section-text__responsive{
    font-size: 2.7vw;
  }

  .landing-section-content{
    padding-bottom: 5vw
  }
}



