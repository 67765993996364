.footer-section.container-fluid{
  background-color: #0D122A;
  color: white;
}

@media (min-width:576px){
  .copyright{
    margin-top:-1rem;
    padding-bottom: 1.2rem;
  }

  .gale-logo{
    padding-top: 0rem!important;
  }
}

@media (max-width:768px){
  .social-media-icons{
    margin-left: auto !important
  }

  .address{
    margin-bottom: 1rem;
  }
}

@media (min-width:768px){
  .address{
    margin-left: auto !important
  }
}

.gale-logo-big{
  width:150px;
}
